import React from "react"
import styled from "styled-components"
import Myra from "../../images/myra.jpeg"
import Layout from "../../components/layout-canada"
import SEO from "../../components/seo"

import LeafBorder from "../../images/hand-painted-leaf-border-cut.png"

const Picture = styled.div`
  background-image: url("${Myra}");
  width: 100%;
  height: 400px;
  background-position: 40%;
  margin-bottom: 42px;
  @media (max-width: 768px) {
    height: 200px;
    background-size: auto 200px;
    background-repeat: no-repeat;
  }
`

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 270px;
  margin-bottom: 32px;
  background-size: 270px 30px;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

const H4 = styled.h4`
  font-family: "Benton Sans";
  margin-bottom: 1.45rem;
`

const Subheader = styled.h3`
  font-family: "Benton Sans";
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const Tab = styled.span`
  padding: 0 15px;
`

class FridayPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Thursday | Sara & Tim" />
        <H1>THURSDAY, JUNE 9TH</H1>
        <LeafBorderPicture />
        <Picture></Picture>
        <Subheader>Myra Canyon and Vineyards</Subheader>
        <H4>Note: These plans are a rough indication and subject to change</H4>
        <P>
          <a href="https://www.tourismkelowna.com/things-to-do/outdoor-recreation/myra-canyon/">
            Myra Canyon
          </a>{" "}
          is a highly-scenic portion of the Kettle Valley Railway that runs
          along a steep-walled canyon. Originally built by hand at the turn of
          the last century, this impressive landmark is highlighted by 2 tunnels
          and 18 trestle bridges that are too spectacular for words.
        </P>
        <P>
          The walk is easy (all flat and along a gravel path) and should take
          around 2.5 to 3 hours (around 10 - 12 km), starting from the Myra
          Station entrance and doing a there-and-back along the trail, aiming to
          get to Tressle Bridge #5.
        </P>
        <P>
          It's easy to turn back at any time, if you'd like to make the walk
          shorter.
        </P>
        <P>
          There are also bikes available for rent from near the entrance, if
          that's more your style.
        </P>
        <P>Bring a packed lunch!</P>
        <P>
          On the way back from Myra into Kelowna, we will be passing some
          fantastic wineries and plan to stop in on one or two for some tastings
          - exact ones to be confirmed so watch this space!
        </P>
        <P>
          11:00<Tab></Tab>Meet at Myra Canyon Station entrance
        </P>
        <P>
          14:00<Tab></Tab>Wine tastings
        </P>
      </Layout>
    )
  }
}

export default FridayPage
