import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Grid } from "@material-ui/core"
import Cocktails from "../images/cocktail-party.jpg"
import AboutUs from "../images/about_us.jpg"
import Rsvp from "../images/rsvp.jpg"
import Gift from "../images/gift.jpg"
import Bed from "../images/bed.jpg"
import Kelowna2 from "../images/kelowna2.jpg"
import Sarsons from "../images/sarsons.jpeg"
import Downtown from "../images/downtown.jpg"
import Downtown2 from "../images/downtown2.webp"
import Myra from "../images/myra.jpeg"

const GridContainer = styled.div`
  margin: 50px 0 40px;
  padding-top: ${props => (props.hideWhereTo ? "0" : "26px")};
  border-top: ${props => (props.hideWhereTo ? "0" : "1px solid #bbb")};
`

const GridItem = styled(Grid)`
  text-align: center;
  font-size: 32px;
  height: 250px;
`

const GridItemContents = styled.div`
  padding: 50px 0;
  margin: 20px 0;
  background-image: url("${props => props.img}");
  background-size: ${props => props.size};
  background-repeat: no-repeat;
  background-position: ${props =>
    props.position ? "center " + props.position : "center"};
`

const GridItemText = styled.div`
  background: rgba(255, 255, 255);
  opacity: 0.8;
  padding: 40px;
`

const Tile = props => {
  return (
    <GridItemContents {...props}>
      <GridItemText>{props.children}</GridItemText>
    </GridItemContents>
  )
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`

const WhereToHeader = styled.h1`
  margin-bottom: 0;
`

const LinkGrid = props => {
  return (
    <GridContainer hideWhereTo={props.hideWhereTo}>
      {props.hideWhereTo || <WhereToHeader>Where to next?</WhereToHeader>}
      <Grid container direction="row" spacing={2}>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/canadian-celebration/thursday">
            <Tile img={Myra} size="cover">
              Thursday
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/canadian-celebration/friday">
            <Tile img={Sarsons} size="cover">
              Friday
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/canadian-celebration/saturday">
            <Tile img={Cocktails} size="100%">
              Saturday
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/canadian-celebration/location">
            <Tile img={Kelowna2} position="20%" size="100%">
              Location & Transport
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/canadian-celebration/where-to-stay">
            <Tile img={Downtown} size="cover">
              Where to stay
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/canadian-celebration/other-things-to-do">
            <Tile img={Downtown2} size="cover">
              Other things to do
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/canadian-celebration/rsvp">
            <Tile img={Rsvp} size="cover">
              RSVP
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/canadian-celebration/about-us">
            <Tile img={AboutUs} size="cover">
              About us
            </Tile>
          </StyledLink>
        </GridItem>
        <GridItem item xs={12} sm={6} md={4}>
          <StyledLink to="/canadian-celebration/gifts">
            <Tile img={Gift} size="cover">
              Gifts
            </Tile>
          </StyledLink>
        </GridItem>
      </Grid>
    </GridContainer>
  )
}

export default LinkGrid
